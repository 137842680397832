import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="PrivacyPolicy page" style={{ textAlign: "left" }}>
        <header>
          <Container>
            <Row>
              <h1>Haftungsausschluss (Disclaimer)</h1>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            <Row>
              <Col>
                <h2>Haftung für Inhalte</h2>
                <p>
                  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                  Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                  verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                  Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach
                  Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                  hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                  Nutzung von Informationen nach den allgemeinen Gesetzen
                  bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                  jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                  Rechtsverletzungen werden wir diese Inhalte umgehend
                  entfernen.
                </p>

                <h2>Haftung für Links</h2>
                <p>
                  Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                  deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                  für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                  die Inhalte der verlinkten Seiten ist stets der jeweilige
                  Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                  mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                  zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                  inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                  Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                  Links umgehend entfernen.
                </p>

                <h2>Urheberrecht</h2>
                <p>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                  Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                  der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den
                  privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
                  Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                  werden die Urheberrechte Dritter beachtet. Insbesondere werden
                  Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                  trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                  bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                  von Rechtsverletzungen werden wir derartige Inhalte umgehend
                  entfernen.
                </p>

                <h2>Datenschutzerklärung:</h2>

                <h3>Datenschutz</h3>
                <p>
                  Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                  personenbezogener Daten möglich. Soweit auf unseren Seiten
                  personenbezogene Daten (beispielsweise Name, Anschrift oder
                  eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                  stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                  ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                </p>
                <p>
                  Wir weisen darauf hin, dass die Datenübertragung im Internet
                  (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                  aufweisen kann. Ein lückenloser Schutz der Daten vor dem
                  Zugriff durch Dritte ist nicht möglich.
                </p>
                <p>
                  Der Nutzung von im Rahmen der Impressumspflicht
                  veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
                  nicht ausdrücklich angeforderter Werbung und
                  Informationsmaterialien wird hiermit ausdrücklich
                  widersprochen. Die Betreiber der Seiten behalten sich
                  ausdrücklich rechtliche Schritte im Falle der unverlangten
                  Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </p>

                <h3>
                  Datenschutzerklärung für die Nutzung von Facebook-Plugins
                  (Like-Button)
                </h3>

                <p>
                  Auf unseren Seiten sind Plugins des sozialen Netzwerks
                  Facebook (Facebook Inc., 1601 Willow Road, Menlo Park,
                  California, 94025, USA) integriert. Die Facebook-Plugins
                  erkennen Sie an dem Facebook-Logo oder dem "Like-Button"
                  ("Gefällt mir") auf unserer Seite. Eine Übersicht über die
                  Facebook-Plugins finden Sie hier:
                  http://developers.facebook.com/docs/plugins/.
                </p>
                <p>
                  Wenn Sie unsere Seiten besuchen, wird über das Plugin eine
                  direkte Verbindung zwischen Ihrem Browser und dem
                  Facebook-Server hergestellt. Facebook erhält dadurch die
                  Information, dass Sie mit Ihrer IP-Adresse unsere Seite
                  besucht haben. Wenn Sie den Facebook "Like-Button" anklicken
                  während Sie in Ihrem Facebook-Account eingeloggt sind, können
                  Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil
                  verlinken. Dadurch kann Facebook den Besuch unserer Seiten
                  Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir
                  als Anbieter der Seiten keine Kenntnis vom Inhalt der
                  übermittelten Daten sowie deren Nutzung durch Facebook
                  erhalten. Weitere Informationen hierzu finden Sie in der
                  Datenschutzerklärung von facebook unter
                  http://de-de.facebook.com/policy.php
                </p>
                <p>
                  Wenn Sie nicht wünschen, dass Facebook den Besuch unserer
                  Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie
                  sich bitte aus Ihrem Facebook-Benutzerkonto aus.
                </p>

                <h3>
                  Datenschutzerklärung für die Nutzung von Google Analytics
                </h3>
                <p>
                  Diese Website benutzt Google Analytics, einen Webanalysedienst
                  der Google Inc. ("Google"). Google Analytics verwendet sog.
                  "Cookies", Textdateien, die auf Ihrem Computer gespeichert
                  werden und die eine Analyse der Benutzung der Website durch
                  Sie ermöglichen. Die durch den Cookie erzeugten Informationen
                  über Ihre Benutzung dieser Website werden in der Regel an
                  einen Server von Google in den USA übertragen und dort
                  gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
                  auf dieser Webseite wird Ihre IP-Adresse von Google jedoch
                  innerhalb von Mitgliedstaaten der Europäischen Union oder in
                  anderen Vertragsstaaten des Abkommens über den Europäischen
                  Wirtschaftsraum zuvor gekürzt.
                </p>
                <p>
                  Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                  Server von Google in den USA übertragen und dort gekürzt. Im
                  Auftrag des Betreibers dieser Website wird Google diese
                  Informationen benutzen, um Ihre Nutzung der Website
                  auszuwerten, um Reports über die Websiteaktivitäten
                  zusammenzustellen und um weitere mit der Websitenutzung und
                  der Internetnutzung verbundene Dienstleistungen gegenüber dem
                  Websitebetreiber zu erbringen. Die im Rahmen von Google
                  Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
                  mit anderen Daten von Google zusammengeführt.
                </p>
                <p>
                  Sie können die Speicherung der Cookies durch eine
                  entsprechende Einstellung Ihrer Browser-Software verhindern;
                  wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                  gegebenenfalls nicht sämtliche Funktionen dieser Website
                  vollumfänglich werden nutzen können. Sie können darüber hinaus
                  die Erfassung der durch das Cookie erzeugten und auf Ihre
                  Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
                  an Google sowie die Verarbeitung dieser Daten durch Google
                  verhindern, indem sie das unter dem folgenden Link verfügbare
                  Browser-Plugin herunterladen und installieren:
                  http://tools.google.com/dlpage/gaoptout?hl=de.
                </p>

                <h3>Datenschutzerklärung für die Nutzung von Google Adsense</h3>
                <p>
                  Diese Website benutzt Google AdSense, einen Dienst zum
                  Einbinden von Werbeanzeigen der Google Inc. ("Google"). Google
                  AdSense verwendet sog. "Cookies", Textdateien, die auf Ihrem
                  Computer gespeichert werden und die eine Analyse der Benutzung
                  der Website ermöglicht. Google AdSense verwendet auch so
                  genannte Web Beacons (unsichtbare Grafiken). Durch diese Web
                  Beacons können Informationen wie der Besucherverkehr auf
                  diesen Seiten ausgewertet werden.
                </p>
                <p>
                  Die durch Cookies und Web Beacons erzeugten Informationen über
                  die Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
                  und Auslieferung von Werbeformaten werden an einen Server von
                  Google in den USA übertragen und dort gespeichert. Diese
                  Informationen können von Google an Vertragspartner von Google
                  weiter gegeben werden. Google wird Ihre IP-Adresse jedoch
                  nicht mit anderen von Ihnen gespeicherten Daten
                  zusammenführen.
                </p>
                <p>
                  Sie können die Installation der Cookies durch eine
                  entsprechende Einstellung Ihrer Browser Software verhindern;
                  wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                  gegebenenfalls nicht sämtliche Funktionen dieser Website voll
                  umfänglich nutzen können. Durch die Nutzung dieser Website
                  erklären Sie sich mit der Bearbeitung der über Sie erhobenen
                  Daten durch Google in der zuvor beschriebenen Art und Weise
                  und zu dem zuvor benannten Zweck einverstanden.
                </p>

                <h3>Datenschutzerklärung für die Nutzung von Google +1</h3>
                <h3>Erfassung und Weitergabe von Informationen:</h3>
                <p>
                  Mithilfe der Google +1-Schaltfläche können Sie Informationen
                  weltweit veröffentlichen. über die Google +1-Schaltfläche
                  erhalten Sie und andere Nutzer personalisierte Inhalte von
                  Google und unseren Partnern. Google speichert sowohl die
                  Information, dass Sie für einen Inhalt +1 gegeben haben, als
                  auch Informationen über die Seite, die Sie beim Klicken auf +1
                  angesehen haben. Ihre +1 können als Hinweise zusammen mit
                  Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa
                  in Suchergebnissen oder in Ihrem Google-Profil, oder an
                  anderen Stellen auf Websites und Anzeigen im Internet
                  eingeblendet werden.
                </p>
                <p>
                  Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um
                  die Google-Dienste für Sie und andere zu verbessern. Um die
                  Google +1-Schaltfläche verwenden zu können, benötigen Sie ein
                  weltweit sichtbares, öffentliches Google-Profil, das zumindest
                  den für das Profil gewählten Namen enthalten muss. Dieser Name
                  wird in allen Google-Diensten verwendet. In manchen Fällen
                  kann dieser Name auch einen anderen Namen ersetzen, den Sie
                  beim Teilen von Inhalten über Ihr Google-Konto verwendet
                  haben. Die Identität Ihres Google-Profils kann Nutzern
                  angezeigt werden, die Ihre E-Mail-Adresse kennen oder über
                  andere identifizierende Informationen von Ihnen verfügen.
                </p>

                <h3>Verwendung der erfassten Informationen:</h3>
                <p>
                  Neben den oben erläuterten Verwendungszwecken werden die von
                  Ihnen bereitgestellten Informationen gemäß den geltenden
                  Google-Datenschutzbestimmungen genutzt. Google veröffentlicht
                  möglicherweise zusammengefasste Statistiken über die
                  +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und
                  Partner weiter, wie etwa Publisher, Inserenten oder verbundene
                  Websites.
                </p>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}
