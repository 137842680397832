import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import ReactMarkdown from "react-markdown";

import { CURRENT_THEME, THEMES } from "../constants";
import { CollapsibleInformation } from "../components/CollapsibleInformation";

import "./FAQ.scss";

const CELLS_ENERGY_FAQ_QUESTIONS = [
  {
    header: null,
    questions: [
      {
        title: "Wie funktioniert der variable Strompreis/ dynamische Tarif? ",
        answer: [
          "Beim dynamischen Tarif wird Strom an der Börse gehandelt, sein Preis passt sich in Echtzeit an Angebot und Nachfrage an. In Zeiten hoher Nachfrage oder geringer Produktion, wie abends oder bei wenig Wind, steigt der Preis. Ist viel Strom verfügbar – etwa bei starkem Wind oder viel Sonnenschein – sinkt der Preis, da das Angebot die Nachfrage übersteigt. Regelmäßig kommt es vor, dass sogar negative Preise entstehen, das heißt: Du wirst dafür belohnt, in diesen Zeitfenstern Strom zu verbrauchen! ",
          "Am Monatsende berechnen wir den Gesamtpreis je Kilowattstunde aus den dokumentierten Stromeinkaufspreisen und weiteren Preisbestandteilen wie Netzentgelten, Abgaben und Umlagen. Zusammen mit deinem voraussichtlichen Jahresverbrauch und den Grundgebühren ergibt sich daraus dein monatlicher Abschlag."
        ]
      },
      {
        title: "Ist der Börsenpreis günstig?",
        answer: [
          "Ja, der Börsenstrompreis kann sehr günstig oder sogar negativ sein – besonders, wenn viel Strom aus erneuerbaren Energien wie Wind und Sonne im Netz ist. Bei unserem dynamischen Tarif profitierst du direkt von diesen Preisschwankungen."
        ]
      },
      {
        title: "Wie sieht es mit der Versorgungssicherheit aus?",
        answer: [
          "Keine Sorge, deine Stromversorgung ist zu jeder Zeit gesichert. Es wird durch uns keine Unterbrechungen geben. Ehrlich gesagt: Das gilt für alle Stromversorger in Deutschland, denn das Stromnetz ist hierzulande sehr stabil und zuverlässig. Dafür sorgt ein ausgeklügeltes System, das jederzeit eine kontinuierliche Versorgung gewährleistet."
        ]
      },
      {
        title: "Wie ist die Qualität meines Stroms?",
        answer: [
          "Die Qualität deines Stroms ist sehr hoch! Mit deinem Stromkauf bei uns unterstützt du direkt die erneuerbaren Stromproduzenten in deiner Region und deines Vertrauens. Dein Geld fließt 1:1 an die Betreiber, die es nutzen, um neue Anlagen zu bauen und bestehende Anlagen nachhaltig zu betreiben. So trägst du aktiv dazu bei, die lokale Energiewende zu fördern und die Zukunft der erneuerbaren Energien zu sichern."
        ]
      },
      {
        title: "Wie wird mein Strom gekennzeichnet?",
        answer: [
          "Da viele Anlagen noch durch das EEG gefördert werden, schreibt das Gesetz eine Deklaration als 'Graustrom' vor. Wir glauben jedoch, dass wir die Energiewende in Deutschland am besten unterstützen, indem wir regionale Anlagenbetreiber zusätzlich mit einem Betreiberzuschuss fördern. So stärken wir gezielt den Ausbau erneuerbarer Energien hier vor Ort – denn wir setzen darauf, die Energiewende in unserer Region voranzutreiben."
        ]
      }
    ]
  }
];

const SWL_FAQ_QUESTIONS = [
  {
    header: "Strom kaufen",
    questions: [
      {
        title: "Wie stelle ich mir einen Anlagen-Mix zusammen?",
        answer: [
          "Mit den Standard-Mixen können Sie einfach bestimmen, was Ihnen besonders wichtig ist. Der elektronische Marktplatz-Agent wählt dann automatisch die besten Anlagen für Sie aus. Sie können die Anordnung durch Ziehen der Profile frei verändern oder Anlagen löschen. Haben Sie leere Plätze, können Sie in der Liste unten oder auf der Landkarte gezielt Anlagen hinzu wählen. Die Anlagen haben eine Reihenfolge. Die Anlage auf der Position 1 ist Ihre Hauptanlage, welche Sie mit dem Betreiberzuschuss unterstützen. Erst wenn Ihr Verbrauch virtuell nicht mehr von dieser Anlage gedeckt werden kann, springen die übrigen Anlagen ein."
        ]
      },
      {
        title: "Was ist eine Deckungsrate?",
        answer: [
          "Die Deckungsrate gibt an, wie hoch die voraussichtliche Stromproduktion des gewählten Anlagenmix, im Vergleich zu Ihrem Jahresverbrauch, ist. Eine Deckungsrate von z.B. 99 % bedeutet demnach, dass die Anlagen aus Ihrem Mix, auf das gesamte Jahr gerechnet, fast die gleiche Menge erneuerbare Energien erzeugen, wie Sie jährlich verbrauchen."
        ]
      },
      {
        title: "Wie funktioniert meine Abrechnung?",
        answer: [
          "Im ersten Schritt werden Ihre voraussichtlichen Gesamtkosten pro Jahr ermittelt. Diese Kosten werden auf 11 monatliche Teilzahlungen, auch Abschlag genannt, aufgeteilt. Am Ende des Abrechnungszeitraums überprüfen wir, wie viel Strom Sie über die Abschläge bereits bezahlt und wie viel Strom Sie tatsächlich verbraucht haben. Ob Sie eine Gutschrift erhalten, oder es zu einer Nachzahlung kommt, teilen wir Ihnen in der jährlichen Jahresendabrechnung mit. "
        ]
      },
      {
        title:
          "Warum weicht die Höhe meines Abschlags von der Berechnung der Gesamtkosten ab?",
        answer: [
          "Der Markplatz-Agent ermittelt die Gesamtkosten und teilt diese gleichmäßig auf 12 Monate auf. Bei uns bezahlen Sie jedoch nur 11 Abschläge (von Februar bis Dezember)."
        ]
      },
      {
        title: "Muss ich den alten Stromvertrag selbst kündigen?",
        answer: [
          "Nein, wir kündigen für Sie kostenlos zum gewünschten Wechseltermin oder zum nächstmöglichen Zeitpunkt. Sollten Sie jedoch bereits vor Antragsstellung selbst bei Ihrem Versorger gekündigt haben, bitten wir Sie, uns dies mitzuteilen."
        ]
      },
      {
        title: "Wie lange dauert der Versorgerwechsel?",
        answer: [
          "Der Versorgerwechsel dauert in der Regel 14 Tage ab Auftragseingang. Sobald der Wechsel vom Netzbetreiber bestätigt wurde, senden wir Ihnen die Vertragsbestätigung mit dem Datum des Belieferungsbeginns zu."
        ]
      },
      {
        title: "Findet eine physikalische Stromlieferung statt?",
        answer: [
          "Es findet keine physikalische Grünstrom-Lieferung, aus den von Ihnen gewählten Anlagen, statt. Die Lieferung erfolgt virtuell und die Grünstromqualität wird über Herkunftsnachweise (HKN) sichergestellt."
        ]
      },
      {
        title: "Wofür ist der Betreiberzuschuss?",
        answer: [
          "Mit dem Betreiberzuschuss leisten Sie als Kunde einen wichtigen Betrag zur Energiewende. Denn mit der Zahlung des Zuschusses, der nach Abzug der Marktplatzgebühr in voller Höhe an die Betreiber geht, können die bestehenden Anlagen erhalten bleiben. Das ist eine wichtige Aufgabe der Bürgerenergiewende, denn ab 2021 fallen jährlich zehntausende Anlagen aus der Förderung. Diese müssen wir erhalten!"
        ]
      }
    ]
  },
  {
    header: "Strom verkaufen",
    questions: [
      {
        title:
          "Welche Fristen muss ich im Anmeldeprozess für die Strom Direktvermarktung einhalten?",
        answer: [
          "Haben Sie eine EEG-Anlage (Neubau- oder Bestandsanlagen, die zukünftig nach dem EEG in Betrieb genommen werden oder dies bereits sind) und wollen deren Strom bei uns in die Direktvermarktung geben, müssen Sie ihre Anlage bis spätestens 1 Monat und 5 Werktage vor dem gewünschten Direktvermarktungsstart in unserem Portal anmelden. Haben Sie eine Anlage, die sich in der Ausfallvergütung befindet oder wechseln Sie von einem anderen Direktvermarkter zu uns, müssen Sie ihre Anlage bis spätestens 10 Tage vor dem gewünschten Direktvermarktungsstart in unserem Portal anmelden.",
          "Die Fernsteuerbarkeit muss in allen diesen Fällen vor dem Start der Direktvermarktung nachgewiesen werden, außer wenn sich der Direktvermarktungsstart und das EEG-Inbetriebnahmedatum im selben Monat befinden. Dann kann der Fernsteuerbarkeitsnachweis bis zum Ende des Folgemonats nachgereicht werden."
        ]
      },
      {
        title: "Wie wird der Erlös auf meiner Abrechnung berechnet?",
        answer: [
          "Wir berechnen den Erlös anhand der ¼-Stunden Einspeisewerte, auch Lastgänge genannt, die uns vom Netzbetreiber via EDIFACT (Electronic Data Interchange for Administration, Commerce and Transport; ein branchenübergreifender Standard für elektronische Daten) übermittelt werden."
        ]
      },
      {
        title: "Für welchen Zeitraum wird jeweils abgerechnet?",
        answer: [
          "Wir rechnen immer für den vorangegangenen Monat ab. Beispiel: Sie haben eine Abrechnung im Februar erhalten, der Abrechnungszeitraum dafür war der Januar."
        ]
      },
      {
        title: "Von wem erhalte ich meine Mehrerlöse?",
        answer: [
          "Wenn Sie die Direktvermarktung nach dem Marktprämienmodell nutzen, bekommen Sie zwei verschiedene Zahlungen. Der Marktwert wird Ihnen von Ihrem Direktvermarkter gezahlt. Auf diese Abrechnung, abzüglich des Dienstleistungsentgeltes, kommt die Umsatzsteuer hinzu. Der Netzbetreiber hingegen bezahlt Ihnen die Marktprämie inklusive der Managementprämie jedoch ohne die Umsatzsteuer. Diese Konstellation nennt sich Zweistrommodell. Der Betreiberzuschuss wird Ihnen von cells energy ausbezahlt."
        ]
      },
      {
        title: "Wann endet für meine Anlage die EEG-Vergütung?",
        answer: [
          "Für alle Anlagen die vor dem 01.01.2000 in Betrieb genommen wurden, läuft die EEG-Vergütung zum 31.12.2020 aus. Für Anlagen die später In Betrieb genommen wurden, endet Sie nach 20 Jahren. Beispiel: Ihre Anlage ging am 15.05.2004 in Betrieb, so endet ihr Anspruch auf Förderung am 31.12.2024."
        ]
      },
      {
        title: "Was bedeutet Post-EEG?",
        answer: [
          "Als Post-EEG bezeichnet man die Zeit ab dem 01.01.2021, da ab diesem Zeitpunkt für die ersten Erneuerbare Energien Anlagen die EEG-Vergütung nach 20 Jahren ausläuft. Zu beachten ist jedoch, dass für Anlagen, welche keinen Anspruch mehr auf eine Vergütung gem. §19 EEG 2021 haben, dennoch die allgemeinen, nicht zahlungsbezogenen Regelungen des EEG gelten. Ausführliche Infos finden Sie unter “Post EEG”."
        ]
      },
      {
        title: "Was ist die sonstige Direktvermarktung?",
        answer: [
          "In der sonstigen Direktvermarktung erhält der Anlagenbetreiber keine Förderung durch das EEG. Er kann seinen Strom dennoch weiterhin über einen Direktvermarkter zum Marktpreis an der Börse verkaufen. Vorteil der sonstigen Direktvermarktung ist, dass die grüne Eigenschaft des Stroms in Form von Herkunftsnachweisen (HKN) vermarktet werden kann."
        ]
      },
      {
        title: "Was muss ich beachten, wenn meine EEG-Vergütung ausläuft?",
        answer: [
          "Zunächst einmal müssen Sie sich entscheiden, welche Art des Weiterbetriebes für Ihre Anlage sinnvoll ist. Im Allgemeinen können Anlagen entweder die Einspeisevergütung für ausgeförderte Anlagen (Abwicklung über den Netzbetreiber) in Anspruch nehmen oder Sie haben die Möglichkeit, Ihren Strom selbst zu verbrauchen. Sollten Sie sich für eine Variante entscheiden, bei welcher Ihre Anlage Strom ins öffentliche Netz einspeist, müssen Sie einen Wechsel in die sonstige Direktvermarktung vornehmen."
        ]
      },
      {
        title: "Wie viel kostet der Service der Fernsteuerbarkeit?",
        answer: [
          "Die Kosten ergeben sich durch den jeweiligen Dienstleister und der bereits vorhandenen Hardware."
        ]
      },
      {
        title: "Was ist ein RLM-Zähler?",
        answer: [
          "Ein RLM-Zähler erfasst den Leistungsmittelwert eines Stromverbrauchers je Messperiode. Die Messperiode beträgt in der Regel bei Strom 15 Minuten und bei Gas 60 Minuten. Dabei können in einem Lastgangzähler mehrere Leistungsmittelwerte gleichzeitig gemessen und gespeichert werden. Die Summe all dieser registrierten Leistungsmittelwerte ergibt das Lastprofil (auch Lastgang genannt). Diesen Messvorgang bezeichnet man auch als registrierende Leistungsmessung oder registrierende Lastgangmessung (RLM). Der RLM-Zähler kann aber nicht nur messen, er kann über standardisierte Schnittstellen seine Messdaten live an beispielsweise Kommunikationseinrichtungen zur Verfügung stellen. Dank dieser Besonderheit kann der RLM-Zähler aus der Ferne über das Internet, den Mobilfunk oder das Stromnetz ausgelesen werden."
        ]
      },
      {
        title: "Wer kann einen RLM-Zähler verwenden?",
        answer: [
          "Ab einem Stromverbrauch von 100.000 kWh im Jahr wird laut der deutschen Stromnetzzugangsverordnung (StromNZV) ein RLM-Zähler Pflicht. In der Regel haben vor allem Industrie- und Gewerbeunternehmen einen so hohen Stromverbrauch und sind demnach von dieser Regelung betroffen. Auch Betreiber von PV-Anlagen mit einer installierten Leistung von mindestens 100 kWp gehören dazu. Diese Grenzen sind aber flexibel. Wenn die Stromverbraucher oder Anlagenbetreiber den Wunsch auf einen RLM-Zähler haben und beispielsweise bei 70.000 kWh Jahresverbrauch liegen, ist der Einbau des Zählers möglich."
        ]
      },
      {
        title: "RLM-Zähler bei PV-Anlagen",
        answer: [
          "Das Erneuerbare-Energien-Gesetz (EEG) schreibt vor, dass PV-Anlagen über 100 kWp seit 2009 fernsteuerbar sein müssen. Zusätzlich muss der Netzbetreiber Zugriff zur Ist-Leistung haben und diese jederzeit einsehen können. Damit das Steuern und Kontrollieren der PV-Anlage einwandfrei funktionieren kann, braucht es neben dem Steuerempfänger eine Messtechnik. Dafür ist der RLM-Zähler zuständig. PV-Anlagen in der Direktvermarktung sind verpflichtet, einen RLM-Zähler einzubauen – unabhängig der installierten Leistung. Darüber hinaus müssen seit 2016 alle EE-Anlagen über 100 kWp in die Direktvermarktung; die Kommunikation mit dem Direktvermarkter erfolgt hierbei mit der Steuerbox, welche mit dem RLM-Zähler vor Ort per Schnittstelle verbunden ist. Die Messdaten werden somit live zum Direktvermarkter übertragen. PV-Anlagen ohne Direktvermarktung und mit einer Größte von 100-500 kW haben die Pflicht, einen RLM-Zähler zu besitzen."
        ]
      }
    ]
  }
];

export default class FAQ extends Component {
  render() {
    const faqQuestions =
      CURRENT_THEME === THEMES.SWL
        ? SWL_FAQ_QUESTIONS
        : CELLS_ENERGY_FAQ_QUESTIONS;

    return (
      <div className="FAQ page">
        <header>
          <Container>
            <Row>
              <h1>Fragen und Antworten</h1>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            {faqQuestions.map((section, index) => (
              <React.Fragment key={index}>
                {!!section.header && <h2>{section.header}</h2>}
                {section.questions.map((question) => {
                  return (
                    <Row key={question.title} className="row-question">
                      <Col lg="8">
                        <CollapsibleInformation title={question.title}>
                          {question.answer.map((paragraph, index) => {
                            return (
                              <ReactMarkdown linkTarget="_blank" key={index}>
                                {paragraph}
                              </ReactMarkdown>
                            );
                          })}
                        </CollapsibleInformation>
                      </Col>
                    </Row>
                  );
                })}
              </React.Fragment>
            ))}
          </Container>
        </main>
      </div>
    );
  }
}
