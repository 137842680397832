import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import StromkennzeichnungImage from "../images/graphics/kennzeichnung_der_stromlieferungen_2025.png";

class Stromkennzeichnung extends Component {
  render() {
    return (
      <div className="Stromkennzeichnung page" style={{ textAlign: "left" }}>
        <header>
          <Container>
            <Row>
              <h3>Stromkennzeichung</h3>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            <Row>
              <Col style={{ marginTop: "30px" }}>
                <p>
                  Unser Strom bei cells energy ist garantiert 100% transparent!
                  Darum weigern wir uns auch, durch „greenwashing“ über gekaufte
                  Zertifikate Strom grün zu färben. Denn das wichtigste ich uns
                  dein Vertrauen!
                </p>
                <p>
                  Bei cells energy bekommst du darum 2 Stromqualitäten, die
                  beide gut fürs Klima sind!
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 style={{ fontWeight: "bold" }}>
                  DIREKTLIEFERUNG FÜR ANLAGEN OHNE FÖRERUNG<sup>1)</sup>
                </h5>
                <span style={{ paddingLeft: "40px" }}>
                  <u>
                    <em>Deine Stromqualität:</em>
                  </u>
                </span>
                <ul>
                  <li>100% Grünstrom direkt aus den Anlagen deiner Wahl!</li>
                  <li>
                    Zeitgleichheit! Der Strom wird zum selben Zeitpunkt erzeugt
                    und von Dir verbraucht!<sup>2)</sup>
                  </li>
                  <li>
                    Du unterstützt direkt deine Region und die Bürgerenergie!
                  </li>
                </ul>
                <p style={{ paddingLeft: "40px" }}>
                  <strong>Update folgt in Kürze!</strong>
                </p>
                <p style={{ paddingLeft: "40px" }}>
                  Wir sind gerade dabei, unsere Stromkennzeichnung zu aktualisieren.
                  Bitte habe noch etwas Geduld.
                </p>
                <ol style={{ fontSize: "10px" }}>
                  <li>
                    Direktlieferung trifft zu auf Strommengen aus Post-EEG
                    Anlagen nach Ende des 20-jährigen Förderzeitraums und aus
                    Anlagen, die auf ihre gesetzliche Förderung verzichten
                    können, weil diese nur geringfügig über dem Börsenpreis
                    (zwischen 2-6 ct/kWh) liegt. Auf deiner Rechnung und online
                    sind diese Anlagen als Direktliefer-Anlagen und die
                    entsprechenden Strommengen als Direktlieferung
                    gekennzeichnet.
                  </li>
                  <li>
                    Ja, es ist leider nicht selbstverständlich, dass Grünstrom
                    auch dann erzeugt wird, wenn du ihn verbrauchst. Es ist
                    gesetzlich sogar erlaubt, Grünstrom, der im Januar erzeugt
                    wurde, im Dezember an dich zu liefern, ohne den Strom
                    zwischenzeitlich zu speichern! Das reicht uns nicht aus!
                    Unser Strom ist immer zeitgleich, denn nur so funktionieren
                    100% Erneuerbare Energien wirklich!
                  </li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 style={{ fontWeight: "bold" }}>
                  ERSATZLIEFERUNG FÜR EEG-ANLAGEN<sup>3)</sup>
                </h5>
                <span style={{ paddingLeft: "40px" }}>
                  <u>
                    <em>Deine Stromqualität:</em>
                  </u>
                </span>
                <ul>
                  <li>
                    Gut fürs Klima! Du hilfst, dass die Anlagen weiter Strom
                    erzeugen!
                  </li>
                  <li>100% Grünstrom, sobald der Förderzeitraum endet!</li>
                  <li>Du förderst direkt die Betreiber Deiner Wahl!</li>
                  <li>
                    Kein „greenwashing“ daher Stromkennzeichnung als „Strom
                    unbekannter Herkunft“ laut gesetzlicher Vorschrift, siehe
                    folgende Grafik
                  </li>
                </ul>
                <img
                  src={StromkennzeichnungImage}
                  alt="Kennzeichnung der Stromlieferungen 2019"
                />
                <ol start="3" style={{ fontSize: "10px" }}>
                  <li>
                    Ersatzlieferung für Mengen aus durch das EEG geförderten
                    Anlagen, auf deiner Rechnung als Ersatzlieferung
                    gekennzeichnet und online für alle Anlagen zutreffend die
                    nicht als Direktlieferanlagen gekennzeichnet sind. Es ist
                    uns gesetzlich untersagt, die Herkunfts-Zertifikate dieser
                    Anlagen an dich weiterzugeben. Wir finden dieses Gesetz
                    verfehlt und behindernd für Bürgerstromkonzepte, müssen uns
                    aber natürlich daran halten. Zertifikate aus z. B.
                    skandinavischer Wasserkraft zu nutzen, um 100% Grünstrom
                    auszuweisen, halten wir für Verbrauchertäuschung! Wir
                    weigern uns daher, bei dem intransparenten System der
                    Stromkennzeichnung mit zu machen. Statt den norwegischen
                    Energiekonzernen erhalten deine Betreiber Vor-Ort den
                    Betreiberzuschuss von dir. In der Stromkennzeichnung müssen
                    wir aber per Gesetz „Strom unbekannter Herkunft“ ausweisen.
                    Deine Kaufentscheidung fördert davon unabhängig direkt die
                    Bürgerenergie in deiner Region, deine Stromkennzeichnung
                    spiegelt einfach die Realität der Stromerzeugung in
                    Deutschland. Sobald alle fossilen und atomaren Kraftwerke in
                    Deutschland vom Netz sind, wofür wir seit 25 Jahren kämpfen,
                    kommt wirklich 100% Grünstrom aus deiner Steckdose – und
                    dann wird auch unsere Ersatzlieferung als 100% Grünstrom
                    gekennzeichnet!
                  </li>
                </ol>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

export { Stromkennzeichnung };
