import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Form, FormGroup, Label, Input, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  Field,
  formValueSelector,
  getFormSubmitErrors,
  reduxForm,
  SubmissionError
} from "redux-form";
import classnames from "classnames";

import { CellCheckbox } from "../../../components/CellCheckbox";
import { ContinueButton } from "../../../components/ContinueButton";
import { checkFeature, FEATURES } from "../../../features";
import { InfoIcon } from "../../../components/InfoIcon";
import { InputField } from "../../../components/InputField";
import { getString } from "../../../utils";

import {
  EMPTY_DROPDOWN,
  REASONS_FOR_CHANGE,
  ALREADY_CANCELLED_ANSWERS,
  STREET_MAX_LENGTH,
  HOUSE_NUMBER_MAX_LENGTH,
  POSTCODE_MAX_LENGTH,
  CITY_MAX_LENGTH,
  METER_NUMBER_MAX_LENGTH,
  CURRENT_SUPPLIER_MAX_LENGTH,
  CUSTOMER_NUMBER_MAX_LENGTH,
  TOMORROW,
  SIX_WEEKS_AGO,
  ONE_YEAR_FROM_TODAY,
  PLZ_REG_EX,
  STRING_KEYS
} from "../../../constants.js";

import "react-datepicker/dist/react-datepicker.css";

const CAN_GIVE_LATER_TEXT =
  "Diese Information kann - falls aktuell nicht zur Hand - nach der Registrierung per E-Mail mitgeteilt werden.";

class ConnectionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connectionDataDifferentThanContractData: false
    };
  }

  handleSubmit = (data) => {
    const errors = this.validateAfterSubmit(data);
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0);
      throw new SubmissionError(errors);
    }

    this.props.onSubmitForm();
  };

  validateAfterSubmit(values) {
    const { connectionDataDifferentThanContractData } = this.state;
    const meterNumberIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_METER_NUMBER_REQUIRED
    );
    const meterCountIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_METER_COUNT
    );
    const currentSupplierIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_CURRENT_SUPPLIER_REQUIRED
    );
    const customerNumberIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_CUSTOMER_NUMBER_REQUIRED
    );
    const errors = {};
    console.log(values);
    if (!values.connectionMeterNumber) {
      errors.connectionMeterNumber = "Bitte Zählernummer eingeben.";
    }

    if (meterCountIsRequired && !values.connectionMeterCount) {
      errors.connectionMeterCount = getString(
        STRING_KEYS.ERROR_METER_COUNT_MISSING
      );
    }

    if (!values.reasonForChange || values.reasonForChange === EMPTY_DROPDOWN) {
      errors.reasonForChange = getString(
        STRING_KEYS.ERROR_REASON_FOR_CHANGE_MISSING
      );
    }

    if (
      !values.startDate &&
      (values.reasonForChange === REASONS_FOR_CHANGE.MOVING ||
        (values.reasonForChange === REASONS_FOR_CHANGE.SWITCHING &&
          values.connectionAlreadyCancelled === ALREADY_CANCELLED_ANSWERS.YES))
    ) {
      errors.startDate = getString(STRING_KEYS.ERROR_START_DATE_MISSING);
    }

    if (connectionDataDifferentThanContractData) {
      if (!values.connectionStreet) {
        errors.connectionStreet = getString(STRING_KEYS.ERROR_STREET_MISSING);
      }

      if (
        !values.connectionPostcode ||
        !PLZ_REG_EX.test(values.connectionPostcode)
      ) {
        errors.connectionPostcode = "Fehler.";
      }

      if (!values.connectionCity) {
        errors.connectionCity = getString(STRING_KEYS.ERROR_CITY_MISSING);
      }
    }

    if (
      values.reasonForChange === REASONS_FOR_CHANGE.SWITCHING &&
      values.connectionAlreadyCancelled === ALREADY_CANCELLED_ANSWERS.NO
    ) {
      if (!values.connectionCurrentSupplier) {
        errors.connectionCurrentSupplier = "Bitte aktuellen Stromversorger eingeben.";
      }

      if (customerNumberIsRequired && !values.connectionCustomerNumber) {
        errors.connectionCustomerNumber = getString(
          STRING_KEYS.ERROR_CUSTOMER_NUMBER_MISSING
        );
      }
    }
    

    return errors;
  }

  handleToggleSameConnectionData = (isDifferent) => {
    this.setState({
      connectionDataDifferentThanContractData: isDifferent
    });
  };

  handleChangeReasonForChange = (selected) => {
    if (selected === REASONS_FOR_CHANGE.MOVING) {
      this.props.change(
        "connectionAlreadyCancelled",
        ALREADY_CANCELLED_ANSWERS.NO
      );
    }
  };

  handleChangeConnectionAlreadyCancelled = (alreadyCancelled) => {
    this.props.change("connectionAlreadyCancelled", alreadyCancelled);

    if (alreadyCancelled === ALREADY_CANCELLED_ANSWERS.YES) {
      this.props.change("connectionCurrentSupplier", null);
      this.props.change("connectionCustomerNumber", null);
    } else if (alreadyCancelled === ALREADY_CANCELLED_ANSWERS.NO) {
      this.props.change("startDate", null);
    }
  };

  componentDidMount() {
    const { reasonForChange } = this.props;

    if (reasonForChange) {
      this.handleChangeReasonForChange(reasonForChange);
    }
  }

  render() {
    const {
      reasonForChange,
      connectionAlreadyCancelled,
      submitErrors,
      handleSubmit
    } = this.props;

    const { connectionDataDifferentThanContractData } = this.state;

    const movingTooltipText = getString(
      STRING_KEYS.CONSUMER_CONTRACT_MOVING_TOOLTIP
    );
    const alreadyCancelledTooltipText = getString(
      STRING_KEYS.CONSUMER_CONTRACT_ALREADY_CANCELLED_TOOLTIP
    );
    const showBeginDeliveryBeforeRevocationPeriodEnds = checkFeature(
      FEATURES.CONSUMER_CONTRACT_BEGIN_DELIVERY_BEFORE_RECOVCATION_PERIOD_ENDS
    );
    const showMeterCount = checkFeature(FEATURES.CONSUMER_CONTRACT_METER_COUNT);
    const meterNumberIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_METER_NUMBER_REQUIRED
    );
    const currentSupplierIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_CURRENT_SUPPLIER_REQUIRED
    );
    const customerNumberIsRequired = checkFeature(
      FEATURES.CONSUMER_CONTRACT_CUSTOMER_NUMBER_REQUIRED
    );
    const meterNumberTooltipText = meterNumberIsRequired
      ? null
      : CAN_GIVE_LATER_TEXT;
    const currentSupplierTooltipText = currentSupplierIsRequired
      ? null
      : CAN_GIVE_LATER_TEXT;

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Container className="contract-fields">
          <Row>
            <Col>
              <h3>Anschluss</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <InputField
                name="connectionMeterNumber"
                label="Zählernummer oder Marktlokation"
                type="text"
                maxLength={METER_NUMBER_MAX_LENGTH}
                required
                error={submitErrors.connectionMeterNumber}
              />
            </Col>
            {showMeterCount && (
              <Col xs={12} md={6} lg={4}>
                <InputField
                  name="connectionMeterCount"
                  label="Zählerstand"
                  inputGroupText="kWh"
                  type="number"
                  required
                  error={submitErrors.connectionMeterCount}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12} md={6} lg={8}>
              <CellCheckbox
                id="sameConnectionData"
                name="sameConnectionData"
                formGroupClassName="bank-sepa"
                inputClassName="same-bank-data"
                text="Abweichende Anschlussadresse"
                checked={connectionDataDifferentThanContractData}
                onChange={this.handleToggleSameConnectionData}
              />
            </Col>
          </Row>
          {connectionDataDifferentThanContractData && (
            <Row>
              <Container>
                <Row>
                  <Col xs={12} md={12} lg={8} className="big-then-small">
                    <InputField
                      name="connectionStreet"
                      label="Straße"
                      type="text"
                      maxLength={STREET_MAX_LENGTH}
                      required
                      error={submitErrors.connectionStreet}
                      inputClasses="full-length"
                    />
                    <InputField
                      name="connectionHouseNumber"
                      label="Hausnr."
                      type="text"
                      maxLength={HOUSE_NUMBER_MAX_LENGTH}
                      required
                      error={submitErrors.connectionHouseNumber}
                      inputClasses="short"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={8} className="small-then-big">
                    <InputField
                      name="connectionPostcode"
                      label="PLZ"
                      type="number"
                      maxLength={POSTCODE_MAX_LENGTH}
                      required
                      error={submitErrors.connectionPostcode}
                      inputClasses="short nospinners"
                    />
                    <InputField
                      name="connectionCity"
                      label="Ort"
                      type="text"
                      maxLength={CITY_MAX_LENGTH}
                      required
                      error={submitErrors.connectionCity}
                      inputClasses="full-length"
                    />
                  </Col>
                </Row>
              </Container>
            </Row>
          )}
          <Row>
            <Col xs={12} md={6} lg={4}>
              <FormGroup>
                <Label for="reasonForChange">Grund für Wechsel</Label>
                <Input
                  type="select"
                  tag={Field}
                  id="reasonForChange"
                  name="reasonForChange"
                  className="shrink"
                  component="select"
                  invalid={!!submitErrors.reasonForChange}
                  onChange={(e) =>
                    this.handleChangeReasonForChange(e.target.value)
                  }
                >
                  <option>{EMPTY_DROPDOWN}</option>
                  {Object.values(REASONS_FOR_CHANGE).map((reason) => (
                    <option key={reason}>{reason}</option>
                  ))}
                </Input>
                {submitErrors.reasonForChange && (
                  <span className="error">{submitErrors.reasonForChange}</span>
                )}
              </FormGroup>
            </Col>
          </Row>
          {reasonForChange === REASONS_FOR_CHANGE.SWITCHING && (
            <Row>
              <Col>
                <Label>
                  {getString(STRING_KEYS.CONSUMER_CONTRACT_ALREADY_CANCELLED)}
                </Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="connectionAlreadyCancelled"
                      className="shrink"
                      value={ALREADY_CANCELLED_ANSWERS.YES}
                      checked={
                        connectionAlreadyCancelled ===
                        ALREADY_CANCELLED_ANSWERS.YES
                      }
                      onChange={(e) =>
                        this.handleChangeConnectionAlreadyCancelled(
                          e.target.value
                        )
                      }
                    />{" "}
                    Ja
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="connectionAlreadyCancelled"
                      className="shrink"
                      value={ALREADY_CANCELLED_ANSWERS.NO}
                      checked={
                        connectionAlreadyCancelled ===
                        ALREADY_CANCELLED_ANSWERS.NO
                      }
                      onChange={(e) =>
                        this.handleChangeConnectionAlreadyCancelled(
                          e.target.value
                        )
                      }
                    />{" "}
                    Nein
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}
          {reasonForChange === REASONS_FOR_CHANGE.SWITCHING &&
            connectionAlreadyCancelled === ALREADY_CANCELLED_ANSWERS.NO && (
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <InputField
                    name="connectionCurrentSupplier"
                    label="Aktueller Stromversorger"
                    type="text"
                    maxLength={CURRENT_SUPPLIER_MAX_LENGTH}
                    required
                    error={submitErrors.connectionCurrentSupplier}
                  />
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <InputField
                    name="connectionCustomerNumber"
                    label="Kundennummer"
                    type="text"
                    maxLength={CUSTOMER_NUMBER_MAX_LENGTH}
                    required={customerNumberIsRequired}
                    error={submitErrors.connectionCustomerNumber}
                  />
                </Col>
              </Row>
            )}
          {reasonForChange && reasonForChange !== EMPTY_DROPDOWN && (
            <Row>
              <Col>
                <p style={{ margin: 0 }}>
                  Startdatum der Belieferung
                  {reasonForChange === REASONS_FOR_CHANGE.MOVING && (
                    <span>
                      {" "}
                      <InfoIcon tooltipName="moving-tooltip">
                        {movingTooltipText}
                      </InfoIcon>
                    </span>
                  )}
                  {reasonForChange === REASONS_FOR_CHANGE.SWITCHING &&
                    connectionAlreadyCancelled ===
                      ALREADY_CANCELLED_ANSWERS.NO && (
                      <span>
                        : Nächstmöglicher Zeitpunkt{" "}
                        <InfoIcon tooltipName="already-cancelled-tooltip">
                          {alreadyCancelledTooltipText}
                        </InfoIcon>
                      </span>
                    )}
                </p>
              </Col>
            </Row>
          )}
          {(reasonForChange === REASONS_FOR_CHANGE.MOVING ||
            (reasonForChange === REASONS_FOR_CHANGE.SWITCHING &&
              connectionAlreadyCancelled ===
                ALREADY_CANCELLED_ANSWERS.YES)) && (
            <Row>
              <Col>
                <FormGroup style={{ display: "flex", flexFlow: "column" }}>
                  <Field
                    name="startDate"
                    component={(props) => (
                      <DatePicker
                        selected={
                          props.input.value !== "" ? props.input.value : null
                        }
                        minDate={
                          reasonForChange === REASONS_FOR_CHANGE.MOVING
                            ? SIX_WEEKS_AGO
                            : TOMORROW
                        }
                        maxDate={ONE_YEAR_FROM_TODAY}
                        placeholderText="TT.MM.JJJJ"
                        dateFormat="dd.MM.yyyy"
                        onChange={(date) => props.input.onChange(date)}
                        className={classnames("form-control", "shrink", {
                          "is-invalid": !!submitErrors.startDate
                        })}
                      />
                    )}
                  />
                  {submitErrors.startDate && (
                    <span className="error">{submitErrors.startDate}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} md={6}>
              <InputField
                name="noteForUs"
                label="Möchtest Du uns noch etwas mitteilen?"
                type="text"
                />
            </Col>
          </Row>
          {showBeginDeliveryBeforeRevocationPeriodEnds && (
            <Row>
              <Col xs={12} md={12} lg={8}>
                <CellCheckbox
                  id="beginDeliveryBeforeRevocationPeriodEnds"
                  name="beginDeliveryBeforeRevocationPeriodEnds"
                  formGroupClassName="bank-sepa"
                  textComponent="Ich verlange ausdrücklich, dass die Energielieferung – soweit möglich – auch beginnen soll, wenn der Lieferbeginn vor Ablauf der Widerrufsfrist liegt. Für den Fall, dass ich mein Widerrufsrecht ausübe, schulde ich dem Lieferanten für die bis zum Widerruf gelieferte Energie gemäß § 357 Abs. 8 BGB einen angemessenen Wertersatz."
                  usesReduxFormField
                />
              </Col>
            </Row>
          )}
        </Container>
        <Container>
          <Row>
            <Col className="continue-button-container">
              <ContinueButton
                onClick={handleSubmit((data) => this.handleSubmit(data))}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

let reduxFormConnectionForm = reduxForm({
  form: "consumerFlowConnectionForm",
  initialValues: {
    startDate: null,
    connectionAlreadyCancelled: ALREADY_CANCELLED_ANSWERS.NO,
    beginDeliveryBeforeRevocationPeriodEnds: false
  },
  destroyOnUnmount: false
})(ConnectionForm);

const selector = formValueSelector("consumerFlowConnectionForm");
reduxFormConnectionForm = connect((state) => {
  const connectionMeterNumber = selector(state, "connectionMeterNumber");
  const connectionMeterCount = selector(state, "connectionMeterCount");
  const connectionCurrentSupplier = selector(
    state,
    "connectionCurrentSupplier"
  );
  const connectionCustomerNumber = selector(state, "connectionCustomerNumber");
  const connectionStreet = selector(state, "connectionStreet");
  const connectionHouseNumber = selector(state, "connectionHouseNumber");
  const connectionPostcode = selector(state, "connectionPostcode");
  const connectionCity = selector(state, "connectionCity");
  const connectionAlreadyCancelled = selector(
    state,
    "connectionAlreadyCancelled"
  );
  const reasonForChange = selector(state, "reasonForChange");
  const startDate = selector(state, "startDate");
  const beginDeliveryBeforeRevocationPeriodEnds = selector(
    state,
    "beginDeliveryBeforeRevocationPeriodEnds"
  );
  const noteForUs = selector(state, "noteForUs");

  return {
    connectionMeterNumber,
    connectionMeterCount,
    connectionCurrentSupplier,
    connectionCustomerNumber,
    connectionStreet,
    connectionHouseNumber,
    connectionPostcode,
    connectionCity,
    connectionAlreadyCancelled,
    reasonForChange,
    startDate,
    beginDeliveryBeforeRevocationPeriodEnds,
    noteForUs
  };
})(reduxFormConnectionForm);

reduxFormConnectionForm = connect((state) => ({
  submitErrors: getFormSubmitErrors("consumerFlowConnectionForm")(state)
}))(reduxFormConnectionForm);

export { reduxFormConnectionForm as ConnectionForm };
